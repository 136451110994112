export const words = [
  "aardwolves",
  "abaci",
  "aboideaux",
  "aboiteaux",
  "abscissae",
  "acanthi",
  "acari",
  "acciaccature",
  "acetabula",
  "achaemenidae",
  "achaemenides",
  "acicula",
  "aciculae",
  "acini",
  "acre-feet",
  "acromia",
  "actiniae",
  "actinozoa",
  "addenda",
  "adenocarcinomata",
  "adenomata",
  "adieux",
  "adyta",
  "aecia",
  "aecidia",
  "aerobia",
  "agents-general",
  "aggiornamenti",
  "agnomina",
  "agones",
  "agorae",
  "agouties",
  "aides-de-camp",
  "aides-memoire",
  "aids-de-camp",
  "alae",
  "alewives",
  "alkalies",
  "allodia",
  "alluvia",
  "alodia",
  "alto-relievos",
  "altocumuli",
  "altostrati",
  "alulae",
  "alumnae",
  "alumni",
  "alveoli",
  "amanuenses",
  "ambulacra",
  "amebae",
  "amici_curiae",
  "amnia",
  "amniocenteses",
  "amoebae",
  "amoebiases",
  "amoraim",
  "amoretti",
  "amorini",
  "amphiarthroses",
  "amphicia",
  "amphimixes",
  "amphioxi",
  "amphisbaenae",
  "amphorae",
  "ampullae",
  "amygdalae",
  "anabases",
  "anacolutha",
  "anacruses",
  "anaerobia",
  "anagnorises",
  "analemmata",
  "analyses",
  "anamneses",
  "anamorphoses",
  "anastomoses",
  "anatyxes",
  "ancones",
  "androclinia",
  "androecia",
  "androsphinges",
  "andtheridia",
  "angelfishes",
  "angiomata",
  "animalcula",
  "anlagen",
  "annattos",
  "annuli",
  "antae",
  "antalkalies",
  "antefixa",
  "antennae",
  "antependia",
  "anthelia",
  "anthelices",
  "anthemia",
  "antheridia",
  "anthodia",
  "anthozoa",
  "anthraces",
  "anticlinoria",
  "antihelices",
  "antiheroes",
  "antisera",
  "antitheses",
  "antitragi",
  "antra",
  "anus",
  "aortae",
  "aphelia",
  "aphides",
  "apices",
  "apodoses",
  "apomixes",
  "aponeuroses",
  "apophyses",
  "aposiopeses",
  "apothecia",
  "apotheoses",
  "apparatus",
  "appendices",
  "appoggiature",
  "apsides",
  "aquae",
  "aquaria",
  "araglis",
  "arboreta",
  "arcana",
  "archegonia",
  "archerfishes",
  "archesporia",
  "archipelagoes",
  "arcs-boutants",
  "areolae",
  "argali",
  "argumenta",
  "ariette",
  "aristae",
  "armamentaria",
  "arses",
  "artal",
  "artel",
  "arterioscleroses",
  "aruspices",
  "asceses",
  "asci",
  "ascidia",
  "ascogonia",
  "ashes",
  "ashkenazim",
  "aspergilla",
  "aspergilli",
  "aspergilloses",
  "aspersoria",
  "assegais",
  "astragali",
  "asyndeta",
  "atheromata",
  "atheroscleroses",
  "atmolyses",
  "atria",
  "attorneys-at-law",
  "auditoria",
  "aurae",
  "aurar",
  "aurar",
  "aurei",
  "auriculae",
  "aurorae",
  "auspices",
  "autocatalyses",
  "autochthones",
  "automata",
  "autos-da-fe",
  "avitaminoses",
  "axes",
  "axillae",
  "bacchantes",
  "bacchii",
  "bacilli",
  "bacteriostases",
  "bacula",
  "bains-marie",
  "bains_marie",
  "ballistae",
  "bambini",
  "bandeaux",
  "banditti",
  "bani",
  "banjoes",
  "barklice",
  "barramundies",
  "bases",
  "bases-on-balls",
  "bases_on_balls",
  "basidia",
  "basileis",
  "bassi",
  "bastinadoes",
  "bateaux",
  "batfishes",
  "beadsmen",
  "beaux",
  "beches-de-mer",
  "beeves",
  "behooves",
  "bersaglieri",
  "bhishties",
  "bibliothecae",
  "bicennaries",
  "bijoux",
  "bilboes",
  "billets-doux",
  "billfishes",
  "bimboes",
  "bisectrices",
  "blackfeet",
  "blackfishes",
  "blastemata",
  "blastulae",
  "blindfishes",
  "blowfishes",
  "bluefishes",
  "boarfishes",
  "bok",
  "boleti",
  "bolivares",
  "bolsheviki",
  "bonefishes",
  "bongoes",
  "bonitoes",
  "booklice",
  "bookshelves",
  "boraces",
  "borborygmi",
  "bordereaux",
  "botargoes",
  "box-kodaks",
  "boxfishes",
  "brachia",
  "brainchildren",
  "branchiae",
  "brants",
  "bravadoes",
  "bravoes",
  "bregmata",
  "brethren",
  "broadcast_media",
  "broadleaves",
  "bronchi",
  "brothers-in-law",
  "bryozoa",
  "buboes",
  "buckoes",
  "buckteeth",
  "buffaloes",
  "bullae",
  "bunde",
  "bureaux",
  "bureaux_de_change",
  "bursae",
  "bushbok",
  "bushboks",
  "busses",
  "butterfishes",
  "byssi",
  "cacti",
  "caducei",
  "caeca",
  "caesurae",
  "calami",
  "calathi",
  "calcanei",
  "calces",
  "calculi",
  "caldaria",
  "calices",
  "calicoes",
  "calli",
  "calves",
  "calyces",
  "cambia",
  "camerae",
  "canaliculi",
  "candelabra",
  "candlefishes",
  "canthi",
  "canulae",
  "canzoni",
  "capita",
  "capitula",
  "capricci",
  "carabinieri",
  "carbonadoes",
  "carcinomata",
  "cargoes",
  "carides",
  "carinae",
  "caroli",
  "carpi",
  "carpogonia",
  "carryings-on",
  "caryopses",
  "caryopsides",
  "castrati",
  "catabases",
  "cataclases",
  "cataloes",
  "catalyses",
  "catenae",
  "catfishes",
  "cathari",
  "cathexes",
  "cattaloes",
  "caudices",
  "caules",
  "cavatine",
  "cavefishes",
  "cavetti",
  "cavo-rilievi",
  "ceca",
  "cellae",
  "cembali",
  "centesimi",
  "centra",
  "cephalothoraces",
  "cercariae",
  "cercariiae",
  "cerci",
  "cerebella",
  "cerebra",
  "cervices",
  "cestuses",
  "cesurae",
  "chadarim",
  "chaetae",
  "chaises_longues",
  "chalazae",
  "challoth",
  "chalutzim",
  "chapaties",
  "chapatties",
  "chapeaux",
  "chasidim",
  "chassidim",
  "chateaux",
  "chazanim",
  "chedarim",
  "chefs-d'ouvre",
  "chelae",
  "chelicerae",
  "cherubim",
  "chevaux-de-frise",
  "chiasmata",
  "chiasmi",
  "children",
  "chillies",
  "chinese_eddoes",
  "chitarroni",
  "chlamydes",
  "chlamyses",
  "chondromata",
  "choragi",
  "choriambi",
  "choux",
  "chromonemata",
  "chrysalides",
  "chuvashes",
  "ciboria",
  "cicadae",
  "cicale",
  "cicatrices",
  "ciceroni",
  "cicisbei",
  "cilia",
  "cimices",
  "cineraria",
  "cingula",
  "cirri",
  "cirrocumuli",
  "cirrostrati",
  "ciscoes",
  "cisternae",
  "clani",
  "clanos",
  "claroes",
  "clepsydrae",
  "clinandria",
  "clingfishes",
  "clitella",
  "cloacae",
  "clostridia",
  "cloverleaves",
  "clypei",
  "coagula",
  "coalfishes",
  "cocci",
  "coccyges",
  "cochleae",
  "codfishes",
  "codices",
  "coelentera",
  "coenuri",
  "cognomina",
  "cognosenti",
  "cola",
  "coleorhizae",
  "collegia",
  "colloquia",
  "colluvia",
  "collyria",
  "colones",
  "colossi",
  "columbaria",
  "columellae",
  "comae",
  "comatulae",
  "comedones",
  "comics",
  "commandoes",
  "concertanti",
  "concerti",
  "concerti_grossi",
  "concertini",
  "conchae",
  "condottieri",
  "condylomata",
  "confervae",
  "congii",
  "conidia",
  "conjunctivae",
  "conquistadores",
  "consortia",
  "contagia",
  "continua",
  "contralti",
  "conversazioni",
  "convolvuli",
  "cooks-general",
  "copulae",
  "corbiculae",
  "coria",
  "corneae",
  "cornua",
  "coronae",
  "corpora",
  "corpora_lutea",
  "corpora_striata",
  "corrigenda",
  "cortices",
  "cortinae",
  "corybantes",
  "coryphaei",
  "costae",
  "cothurni",
  "courts_martial",
  "couteaux",
  "cowfishes",
  "coxae",
  "cramboes",
  "crania",
  "crases",
  "crawfishes",
  "crayfishes",
  "credenda",
  "crematoria",
  "crescendi",
  "cribella",
  "crises",
  "crissa",
  "cristae",
  "criteria",
  "cruces",
  "crura",
  "crusadoes",
  "cruzadoes",
  "crying",
  "cryings",
  "ctenidia",
  "cubicula",
  "culices",
  "culpae",
  "culs-de-sac",
  "culti",
  "cumuli",
  "cumulonimbi",
  "cumulostrati",
  "curiae",
  "curricula",
  "custodes",
  "cutes",
  "cuticulae",
  "cuttlefishes",
  "cyclopes",
  "cycloses",
  "cylices",
  "cylikes",
  "cymae",
  "cymatia",
  "cypselae",
  "cysticerci",
  "dadoes",
  "dagoes",
  "damselfishes",
  "data",
  "daughters-in-law",
  "daymio",
  "daymios",
  "dealfishes",
  "decemviri",
  "decennia",
  "deciduae",
  "definienda",
  "definientia",
  "delphinia",
  "denarii",
  "dentalia",
  "dermatoses",
  "desiderata",
  "desperadoes",
  "devilfishes",
  "diaereses",
  "diaerses",
  "diagnoses",
  "dialyses",
  "diaphyses",
  "diapophyses",
  "diarthroses",
  "diastalses",
  "diastases",
  "diastemata",
  "diastemata",
  "diathses",
  "diazoes",
  "dibbukkim",
  "dichasia",
  "dicta",
  "didoes",
  "diereses",
  "dieses",
  "differentiae",
  "dilettanti",
  "diluvia",
  "dingoes",
  "diplococci",
  "directors-general",
  "disci",
  "discoboli",
  "dive",
  "diverticula",
  "divertimenti",
  "djinn",
  "dodoes",
  "dogfishes",
  "dogmata",
  "dogteeth",
  "dollarfishes",
  "domatia",
  "dominoes",
  "dormice",
  "dorsa",
  "drachmae",
  "drawknives",
  "drosophilae",
  "drumfishes",
  "dryades",
  "dui",
  "duona",
  "duonas",
  "dupondii",
  "duumviri",
  "dwarves",
  "dybbukkim",
  "ecchymoses",
  "ecclesiae",
  "ecdyses",
  "echidnae",
  "echini",
  "echinococci",
  "echoes",
  "ectozoa",
  "eddoes",
  "edemata",
  "effluvia",
  "eidola",
  "eisegeses",
  "eisteddfodau",
  "elenchi",
  "ellipses",
  "eluvia",
  "elves",
  "elytra",
  "embargoes",
  "emboli",
  "emphases",
  "emporia",
  "enarthroses",
  "encephala",
  "encephalitides",
  "encephalomata",
  "enchiridia",
  "enchondromata",
  "encomia",
  "endamebae",
  "endamoebae",
  "endocardia",
  "endocrania",
  "endometria",
  "endostea",
  "endostoses",
  "endothecia",
  "endothelia",
  "endotheliomata",
  "endozoa",
  "enemata",
  "enneahedra",
  "entamebae",
  "entamoebae",
  "entases",
  "entera",
  "entia",
  "entozoa",
  "epencephala",
  "epentheses",
  "epexegeses",
  "ephemera",
  "ephemerae",
  "ephemerides",
  "ephori",
  "epicalyces",
  "epicanthi",
  "epicardia",
  "epicedia",
  "epicleses",
  "epididymides",
  "epigastria",
  "epiglottides",
  "epimysia",
  "epiphenomena",
  "epiphyses",
  "episterna",
  "epithalamia",
  "epithelia",
  "epitheliomata",
  "epizoa",
  "epyllia",
  "equilibria",
  "equiseta",
  "eringoes",
  "errata",
  "eryngoes",
  "esophagi",
  "etyma",
  "eucalypti",
  "eupatridae",
  "euripi",
  "exanthemata",
  "executrices",
  "exegeses",
  "exempla",
  "exordia",
  "exostoses",
  "extrema",
  "eyeteeth",
  "fabliaux",
  "faciae",
  "faculae",
  "faeroese",
  "fallfishes",
  "famuli",
  "farmers-general",
  "faroese",
  "farragoes",
  "fasciae",
  "fasciculi",
  "fathers-in-law",
  "fatsoes",
  "faunae",
  "feculae",
  "fedayeen",
  "feet",
  "fellaheen",
  "fellahin",
  "felones_de_se",
  "felos_de_se",
  "femora",
  "fenestellae",
  "fenestrae",
  "feriae",
  "fermate",
  "ferulae",
  "festschriften",
  "fetiales",
  "fezzes",
  "fiascoes",
  "fibrillae",
  "fibromata",
  "fibulae",
  "ficoes",
  "fideicommissa",
  "fieldmice",
  "figs.",
  "fila",
  "filariiae",
  "filefishes",
  "fimbriae",
  "fishes",
  "fishwives",
  "fistulae",
  "flabella",
  "flagella",
  "flagstaves",
  "flambeaux",
  "flamines",
  "flamingoes",
  "flatfeet",
  "flatfishes",
  "fleurs-de-lis",
  "fleurs-de-lys",
  "flights_of_stairs",
  "flittermice",
  "flocci",
  "flocculi",
  "florae",
  "floreant.",
  "florilegia",
  "flowers-de-luce",
  "flyleaves",
  "foci",
  "folia",
  "fora",
  "foramina",
  "forceps",
  "forefeet",
  "foreteeth",
  "formicaria",
  "formulae",
  "fornices",
  "fortes",
  "fossae",
  "foveae",
  "foveolae",
  "fractocumuli",
  "fractostrati",
  "fraena",
  "frauen",
  "frena",
  "frenula",
  "frescoes",
  "fricandeaux",
  "fricandoes",
  "frijoles",
  "frogfishes",
  "frontes",
  "frusta",
  "fuci",
  "fulcra",
  "fumatoria",
  "fundi",
  "fungi",
  "funiculi",
  "furcula",
  "furculae",
  "furfures",
  "galeae",
  "gambadoes",
  "gametangia",
  "gametoecia",
  "gammadia",
  "ganglia",
  "garfishes",
  "gas",
  "gasses",
  "gastrulae",
  "gateaux",
  "gazeboes",
  "geckoes",
  "geese",
  "gelsemia",
  "gemboks",
  "gembucks",
  "gemeinschaften",
  "gemmae",
  "genera",
  "generatrices",
  "geneses",
  "genii",
  "gentes",
  "gentlemen-at-arms",
  "gentlemen-farmers",
  "genua",
  "genus",
  "germina",
  "gesellschaften",
  "gestalten",
  "ghettoes",
  "gingivae",
  "gingkoes",
  "ginglymi",
  "ginkgoes",
  "gippoes",
  "glabellae",
  "gladioli",
  "glandes",
  "gliomata",
  "glissandi",
  "globefishes",
  "globigerinae",
  "glochidcia",
  "glochidia",
  "glomeruli",
  "glossae",
  "glottides",
  "glutaei",
  "glutei",
  "gnoses",
  "goatfishes",
  "goboes",
  "godchildren",
  "goes",
  "goings-over",
  "goldfishes",
  "gomphoses",
  "gonia",
  "gonidia",
  "gonococci",
  "goodwives",
  "goosefishes",
  "gorgoneia",
  "gospopoda",
  "governors_general",
  "goyim",
  "gps",
  "grafen",
  "graffiti",
  "grandchildren",
  "grants-in-aid",
  "granulomata",
  "gravamina",
  "grig-gris",
  "groszy",
  "grottoes",
  "guilder",
  "guilders",
  "guitarfishes",
  "gummata",
  "gurnard",
  "gurnards",
  "guttae",
  "gymnasia",
  "gynaecea",
  "gynaecia",
  "gynecea",
  "gynecia",
  "gynoecea",
  "gynoecia",
  "gyri",
  "hadarim",
  "hadjes",
  "haematolyses",
  "haematomata",
  "haematozoa",
  "haemodialyses",
  "haemolyses",
  "haemoptyses",
  "haeredes",
  "haftaroth",
  "hagfishes",
  "haggadas",
  "haggadoth",
  "hajjes",
  "haleru",
  "halfpence",
  "hallot",
  "halloth",
  "halluces",
  "haloes",
  "halteres",
  "halves",
  "hamuli",
  "hangers-on",
  "haphtaroth",
  "haredim",
  "haruspices",
  "hasidim",
  "hassidim",
  "haustella",
  "haustoria",
  "hazzanim",
  "hectocotyli",
  "heirs-at-law",
  "heldentenore",
  "helices",
  "heliozoa",
  "hematolyses",
  "hematomata",
  "hematozoa",
  "hemelytra",
  "hemielytra",
  "hemodialyses",
  "hemolyses",
  "hemoptyses",
  "hendecahedra",
  "hens-and-chickens",
  "heraclidae",
  "heraklidae",
  "herbaria",
  "hermae",
  "hermai",
  "herniae",
  "heroes",
  "herren",
  "hetaerae",
  "hetairai",
  "hibernacula",
  "hieracosphinges",
  "hila",
  "hili",
  "himatia",
  "hippocampi",
  "hippopotami",
  "his",
  "hoboes",
  "hogfishes",
  "homunculi",
  "honoraria",
  "hooves",
  "horologia",
  "houses_of_cards",
  "housewives",
  "humeri",
  "hydrae",
  "hydromedusae",
  "hydrozoa",
  "hymenoptera",
  "hynia",
  "hyniums",
  "hypanthia",
  "hyperostoses",
  "hyphae",
  "hypnoses",
  "hypochondria",
  "hypogastria",
  "hypogea",
  "hypophyses",
  "hypostases",
  "hypothalami",
  "hypotheses",
  "hyraces",
  "iambi",
  "ibices",
  "ibo",
  "ichthyosauri",
  "ichthyosauruses",
  "iconostases",
  "icosahedra",
  "ideata",
  "igorrorote",
  "ilia",
  "imagines",
  "imagoes",
  "imperia",
  "impies",
  "incubi",
  "incudes",
  "indices",
  "indigoes",
  "indumenta",
  "indusia",
  "infundibula",
  "ingushes",
  "innuendoes",
  "inocula",
  "inquisitors-general",
  "insectaria",
  "insulae",
  "intagli",
  "interleaves",
  "intermezzi",
  "interreges",
  "interregna",
  "intimae",
  "involucella",
  "involucra",
  "involucra",
  "irides",
  "irs",
  "is",
  "ischia",
  "isthmi",
  "jackeroos",
  "jackfishes",
  "jackknives",
  "jacks-in-the-box",
  "jambeaux",
  "jellyfishes",
  "jewelfishes",
  "jewfishes",
  "jingoes",
  "jinn",
  "joes",
  "judge_advocates_general",
  "jura",
  "kaddishim",
  "kalmuck",
  "kalmucks",
  "katabases",
  "keeshonden",
  "kibbutzim",
  "killifishes",
  "kingfishes",
  "kings-of-arms",
  "knights_bachelor",
  "knights_bachelors",
  "knights_templar",
  "knights_templars",
  "knives",
  "kohlrabies",
  "kronen",
  "kroner",
  "kronur",
  "krooni",
  "kylikes",
  "labara",
  "labella",
  "labia",
  "labra",
  "lactobacilli",
  "lacunae",
  "lacunaria",
  "ladies-in-waiting",
  "lamellae",
  "lamiae",
  "laminae",
  "lapilli",
  "lapithae",
  "larvae",
  "larynges",
  "lassoes",
  "lati",
  "latices",
  "latifundia",
  "latu",
  "lavaboes",
  "leaves",
  "lecythi",
  "leges",
  "lei",
  "lemmata",
  "lemnisci",
  "lenes",
  "lentigines",
  "leonides",
  "lepidoptera",
  "leprosaria",
  "lepta",
  "leptocephali",
  "leucocytozoa",
  "leva",
  "librae",
  "libretti",
  "lice",
  "lieder",
  "ligulae",
  "limbi",
  "limina",
  "limites",
  "limuli",
  "lingoes",
  "linguae",
  "linguae_francae",
  "lionfishes",
  "lipomata",
  "lire",
  "liriodendra",
  "lisente",
  "listente",
  "litai",
  "litu",
  "lives",
  "lixivia",
  "loaves",
  "loci",
  "loculi",
  "loggie",
  "logia",
  "lomenta",
  "longobardi",
  "loricae",
  "loups-garous",
  "luba",
  "lubritoria",
  "lumbi",
  "lumina",
  "lumpfishes",
  "lungfishes",
  "lunulae",
  "lures",
  "lustra",
  "lyings-in",
  "lymphangitides",
  "lymphomata",
  "lymphopoieses",
  "lyses",
  "lyttae",
  "maare",
  "macaronies",
  "maccaronies",
  "machzorim",
  "macronuclei",
  "macrosporangia",
  "maculae",
  "madornos",
  "maestri",
  "mafiosi",
  "magi",
  "magmata",
  "magnificoes",
  "mahzorim",
  "major-axes",
  "major_axes",
  "makuta",
  "mallei",
  "malleoli",
  "maloti",
  "mamillae",
  "mammae",
  "mammillae",
  "mandingoes",
  "mangoes",
  "manifestoes",
  "manteaux",
  "mantes",
  "manubria",
  "marchese",
  "marchesi",
  "maremme",
  "markkaa",
  "marsupia",
  "marvels-of-peru",
  "mass_media",
  "masses",
  "masters-at-arms",
  "matrices",
  "matzoth",
  "mausolea",
  "maxillae",
  "maxima",
  "media",
  "mediae",
  "mediastina",
  "medullae",
  "medullae_oblongatae",
  "medusae",
  "megara",
  "megasporangia",
  "megilloth",
  "meioses",
  "melanomata",
  "melismata",
  "mementoes",
  "memoranda",
  "men",
  "men-at-arms",
  "men-o'-war",
  "men-of-war",
  "men_of_letters",
  "menisci",
  "menservants",
  "menstrua",
  "mesdames",
  "mesdemoiselles",
  "mesentera",
  "mesothoraces",
  "messeigneurs",
  "messieurs",
  "mestizoes",
  "metacarpi",
  "metamorphoses",
  "metanephroi",
  "metastases",
  "metatarsi",
  "metatheses",
  "metathoraces",
  "metazoa",
  "metempsychoses",
  "metencephala",
  "mezuzoth",
  "miasmata",
  "mice",
  "microanalyses",
  "micrococci",
  "micronuclei",
  "microsporangia",
  "midrashim",
  "midwives",
  "milia",
  "milieux",
  "militated_against",
  "milkfishes",
  "millennia",
  "minae",
  "minima",
  "ministeria",
  "minutiae",
  "minyanim",
  "mioses",
  "miracidia",
  "miri",
  "mishnayoth",
  "mitochondria",
  "mitzvoth",
  "modioli",
  "moduli",
  "momenta",
  "moments_of_truth",
  "momi",
  "monades",
  "monkfishes",
  "monochasia",
  "monopodia",
  "monoptera",
  "monopteroi",
  "monsignori",
  "monts-de-piete",
  "mooncalves",
  "moonfishes",
  "morae",
  "moratoria",
  "morceaux",
  "morescoes",
  "moriscoes",
  "morphallaxes",
  "morphoses",
  "morses",
  "morulae",
  "mosasauri",
  "moshavim",
  "moslim",
  "moslims",
  "mosquitoes",
  "mothers-in-law",
  "mothers_superior",
  "mottoes",
  "movers_and_shakers",
  "mucosae",
  "mucrones",
  "mudejares",
  "mudfishes",
  "mulattoes",
  "multiparae",
  "murices",
  "muskallunge",
  "mycelia",
  "mycetomata",
  "mycobacteria",
  "mycorrhizae",
  "myelencephala",
  "myiases",
  "myocardia",
  "myofibrillae",
  "myomata",
  "myoses",
  "myrmidones",
  "mythoi",
  "myxomata",
  "naevi",
  "naiades",
  "naoi",
  "narcissi",
  "nares",
  "nasopharynges",
  "natatoria",
  "naumachiae",
  "nauplii",
  "nautili",
  "navahoes",
  "navajoes",
  "nebulae",
  "necropoleis",
  "needlefishes",
  "negrilloes",
  "negritoes",
  "negroes",
  "nemeses",
  "nephridia",
  "nereides",
  "neurohypophyses",
  "neuromata",
  "neuroptera",
  "neuroses",
  "nevi",
  "nibelungen",
  "nidi",
  "nielli",
  "nilgai",
  "nimbi",
  "nimbostrati",
  "noctilucae",
  "nodi",
  "noes",
  "nomina",
  "nota",
  "noumena",
  "novae",
  "novelle",
  "novenae",
  "nubeculae",
  "nucelli",
  "nuchae",
  "nuclei",
  "nucleoli",
  "nulliparae",
  "numbfishes",
  "numina",
  "nymphae",
  "oarfishes",
  "oases",
  "obeli",
  "objets_d'art",
  "obligati",
  "oboli",
  "occipita",
  "oceanaria",
  "oceanides",
  "ocelli",
  "ochreae",
  "ocreae",
  "octahedra",
  "octopi",
  "oculi",
  "odea",
  "oedemata",
  "oesophagi",
  "oldwives",
  "olea",
  "omasa",
  "omayyades",
  "omenta",
  "ommatidia",
  "ommiades",
  "onagri",
  "oogonia",
  "oothecae",
  "operas_seria",
  "opercula",
  "optic_axes",
  "optima",
  "ora",
  "organa",
  "organums",
  "orthoptera",
  "osar",
  "oscula",
  "ossa",
  "osteomata",
  "ostia",
  "ottomans",
  "ova",
  "ovoli",
  "ovotestes",
  "oxen",
  "oxymora",
  "paddlefishes",
  "paise",
  "paleae",
  "palestrae",
  "palingeneses",
  "pallia",
  "palmettoes",
  "palpi",
  "pancratia",
  "panettoni",
  "paparazzi",
  "paperknives",
  "papillae",
  "papillomata",
  "pappi",
  "papulae",
  "papyri",
  "parabases",
  "paraleipses",
  "paralyses",
  "paramecia",
  "paramenta",
  "paraphyses",
  "parapodia",
  "parapraxes",
  "paraselenae",
  "parashoth",
  "parasyntheta",
  "parazoa",
  "parentheses",
  "parerga",
  "parhelia",
  "parietes",
  "paris-mutuels",
  "parrotfishes",
  "parulides",
  "pasos_dobles",
  "passers-by",
  "pastorali",
  "patagia",
  "patellae",
  "patinae",
  "patresfamilias",
  "pease",
  "peccadilloes",
  "pectines",
  "pedaloes",
  "pedes",
  "pekingese",
  "pelves",
  "pence",
  "penes",
  "penetralia",
  "penicillia",
  "penknives",
  "pennae",
  "pennia",
  "pentahedra",
  "pentimenti",
  "penumbrae",
  "pepla",
  "pericardia",
  "perichondria",
  "pericrania",
  "peridia",
  "perigonia",
  "perihelia",
  "perinea",
  "perinephria",
  "perionychia",
  "periostea",
  "periphrases",
  "peristalses",
  "perithecia",
  "peritonea",
  "personae",
  "petechiae",
  "pfennige",
  "phalanges",
  "phalli",
  "pharynges",
  "phenomena",
  "phi-phenomena",
  "philodendra",
  "phlyctenae",
  "phyla",
  "phylae",
  "phyllotaxes",
  "phylloxerae",
  "phylogeneses",
  "pieds-a-terre",
  "pigfishes",
  "pilea",
  "pilei",
  "pineta",
  "pinfishes",
  "pinkoes",
  "pinnae",
  "pinnulae",
  "pipefishes",
  "pirogi",
  "piscinae",
  "pithecanthropi",
  "pithoi",
  "placeboes",
  "placentae",
  "planetaria",
  "planulae",
  "plasmodesmata",
  "plasmodia",
  "plateaux",
  "plectra",
  "plena",
  "pleura",
  "pleurae",
  "plicae",
  "ploughmen",
  "pneumobacilli",
  "pneumococci",
  "pocketknives",
  "podetia",
  "podia",
  "poleis",
  "pollices",
  "pollinia",
  "polychasia",
  "polyhedra",
  "polyparia",
  "polypi",
  "polyzoa",
  "polyzoaria",
  "pontes",
  "pontifices",
  "portamenti",
  "porticoes",
  "portmanteaux",
  "postliminia",
  "potatoes",
  "praenomina",
  "praxes",
  "predelle",
  "premaxillae",
  "prenomina",
  "prese",
  "primi",
  "primigravidae",
  "primiparae",
  "primordia",
  "principia",
  "proboscides",
  "proces-verbaux",
  "proglottides",
  "prognoses",
  "prolegomena",
  "prolepses",
  "promycelia",
  "pronephra",
  "pronephroi",
  "pronuclei",
  "propositi",
  "proptoses",
  "propyla",
  "propylaea",
  "proscenia",
  "prosencephala",
  "prostheses",
  "prostomia",
  "protases",
  "prothalamia",
  "prothalli",
  "prothallia",
  "prothoraces",
  "protonemata",
  "protozoa",
  "proventriculi",
  "provisoes",
  "prytanea",
  "psalteria",
  "pseudopodia",
  "psychoneuroses",
  "psychoses",
  "pterygia",
  "pterylae",
  "ptoses",
  "pubes",
  "pudenda",
  "puli",
  "pulvilli",
  "pulvini",
  "punchinelloes",
  "pupae",
  "puparia",
  "putamina",
  "putti",
  "pycnidia",
  "pygidia",
  "pylori",
  "pyxides",
  "pyxidia",
  "qaddishim",
  "quadrennia",
  "quadrigae",
  "qualia",
  "quanta",
  "quarterstaves",
  "quezales",
  "quinquennia",
  "quizzes",
  "rabatos",
  "rabbitfishes",
  "rachides",
  "radices",
  "radii",
  "radulae",
  "ramenta",
  "rami",
  "ranulae",
  "ranunculi",
  "raphae",
  "raphides",
  "ratfishes",
  "reales",
  "rearmice",
  "recta",
  "recti",
  "rectrices",
  "redfishes",
  "rediae",
  "referenda",
  "refugia",
  "reguli",
  "reis",
  "relata",
  "remiges",
  "reremice",
  "reseaux",
  "residua",
  "responsa",
  "retia",
  "retiarii",
  "reticula",
  "retinacula",
  "retinae",
  "rhabdomyomata",
  "rhachides",
  "rhachises",
  "rhinencephala",
  "rhizobia",
  "rhombi",
  "rhonchi",
  "rhyta",
  "ribbonfishes",
  "ricercacari",
  "ricercari",
  "rickettsiae",
  "rilievi",
  "rimae",
  "robes-de-chambre",
  "rockfishes",
  "roma",
  "romans-fleuves",
  "rondeaux",
  "rosaria",
  "rosefishes",
  "rostella",
  "rostra",
  "rouleaux",
  "rugae",
  "rumina",
  "runners-up",
  "sacra",
  "sacraria",
  "saguaros",
  "sailfishes",
  "salespeople",
  "salmonellae",
  "salpae",
  "salpinges",
  "saltarelli",
  "salvoes",
  "sancta",
  "sanitaria",
  "santimi",
  "saphenae",
  "sarcophagi",
  "sartorii",
  "sassanidae",
  "sawfishes",
  "scaldfishes",
  "scaleni",
  "scapulae",
  "scarabaei",
  "scarves",
  "schatchonim",
  "schemata",
  "scherzandi",
  "scherzi",
  "schmoes",
  "scholia",
  "schuln",
  "schutzstaffeln",
  "scirrhi",
  "scleromata",
  "scleroses",
  "sclerotia",
  "scoleces",
  "scolices",
  "scopulae",
  "scoriae",
  "scotomata",
  "scriptoria",
  "scrota",
  "scudi",
  "scuta",
  "scutella",
  "scyphi",
  "scyphistomae",
  "scyphozoa",
  "secondi",
  "secretaries-general",
  "segni",
  "seleucidae",
  "selves",
  "senores",
  "sensilla",
  "senti",
  "senussis",
  "separatrices",
  "sephardim",
  "septa",
  "septaria",
  "septennia",
  "sequelae",
  "sequestra",
  "sera",
  "seraphim",
  "sestertia",
  "setae",
  "sgraffiti",
  "shabbasim",
  "shabbatim",
  "shackoes",
  "shadchanim",
  "shadchans",
  "shakoes",
  "shammosim",
  "sheatfishes",
  "sheaves",
  "shellfishes",
  "shelves",
  "shinleaves",
  "shittim",
  "shmoes",
  "shofroth",
  "shophroth",
  "shrewmice",
  "shuln",
  "siddurim",
  "sigloi",
  "signore",
  "signori",
  "signorine",
  "siliquae",
  "silvae",
  "silverfishes",
  "simulacra",
  "sincipita",
  "sinfonie",
  "sisters-in-law",
  "sistra",
  "situlae",
  "smalti",
  "snaggleteeth",
  "snailfishes",
  "snipefishes",
  "socmen",
  "sola",
  "solaria",
  "solatia",
  "soldi",
  "soles",
  "solfeggi",
  "soli",
  "solidi",
  "somata",
  "sons-in-law",
  "soprani",
  "sordini",
  "sori",
  "soroses",
  "sovkhozy",
  "spadefishes",
  "spadices",
  "spearfishes",
  "spectra",
  "specula",
  "spermatia",
  "spermatogonia",
  "spermatozoa",
  "spermogonia",
  "sphinges",
  "spicae",
  "spicula",
  "spirilla",
  "splayfeet",
  "splenii",
  "sporangia",
  "sporogonia",
  "sporozoa",
  "springhase",
  "spumoni",
  "sputa",
  "squamae",
  "squashes",
  "squillae",
  "squirrelfishes",
  "squizzes",
  "stadia",
  "stamina",
  "staminodia",
  "stapedes",
  "staphylococci",
  "staretsy",
  "starfishes",
  "startsy",
  "stelae",
  "stemmata",
  "stenoses",
  "stepchildren",
  "sterna",
  "stigmata",
  "stimuli",
  "stipites",
  "stirpes",
  "stoae",
  "stockfishes",
  "stomata",
  "stomodaea",
  "stomodea",
  "stonefishes",
  "stotinki",
  "stotkini",
  "strappadoes",
  "strata",
  "strati",
  "stratocumuli",
  "street_children",
  "streptococci",
  "stretti",
  "striae",
  "strobili",
  "stromata",
  "strumae",
  "stuccoes",
  "styli",
  "stylopes",
  "stylopodia",
  "subcortices",
  "subdeliria",
  "subgenera",
  "subindices",
  "submucosae",
  "subphyla",
  "substrasta",
  "succedanea",
  "succubi",
  "suckerfishes",
  "suckfishes",
  "sudaria",
  "sudatoria",
  "sudatoria",
  "sulci",
  "summae",
  "sunfishes",
  "supercargoes",
  "superheroes",
  "supernovae",
  "superstrata",
  "surgeonfishes",
  "swamies",
  "sweetiewives",
  "swellfishes",
  "swordfishes",
  "syconia",
  "syllabi",
  "syllepses",
  "symphyses",
  "sympodia",
  "symposia",
  "synapses",
  "synarthroses",
  "synclinoria",
  "syncytia",
  "syndesmoses",
  "synopses",
  "syntagmata",
  "syntheses",
  "syphilomata",
  "syringes",
  "syssarcoses",
  "tableaux",
  "taeniae",
  "tali",
  "tallaisim",
  "tallithes",
  "tallitoth",
  "tapeta",
  "tarantulae",
  "tarsi",
  "tarsometatarsi",
  "taxa",
  "taxes",
  "taxies",
  "tectrices",
  "teeth",
  "tegmina",
  "telae",
  "telamones",
  "telangiectases",
  "telia",
  "tempi",
  "tenacula",
  "tenderfeet",
  "teniae",
  "tenues",
  "teraphim",
  "terata",
  "teredines",
  "terga",
  "termini",
  "terraria",
  "terzetti",
  "tesserae",
  "testae",
  "testes",
  "testudines",
  "tetrahedra",
  "tetraskelia",
  "thalamencephala",
  "thalami",
  "thalli",
  "theatres-in-the-round",
  "thecae",
  "therses",
  "thesauri",
  "theses",
  "thickleaves",
  "thieves",
  "tholoi",
  "thoraces",
  "thrombi",
  "thymi",
  "thyrsi",
  "tibiae",
  "tilefishes",
  "tintinnabula",
  "titmice",
  "toadfishes",
  "tobaccoes",
  "tomatoes",
  "tomenta",
  "tondi",
  "tonneaux",
  "tophi",
  "topoi",
  "tori",
  "tornadoes",
  "torpedoes",
  "torsi",
  "touracos",
  "trabeculae",
  "tracheae",
  "traditores",
  "tragi",
  "trapezia",
  "trapezohedra",
  "traumata",
  "treponemata",
  "trichinae",
  "triclinia",
  "triennia",
  "triforia",
  "triggerfishes",
  "trihedra",
  "triskelia",
  "trisoctahedra",
  "triumviri",
  "trivia",
  "trochleae",
  "tropaeola",
  "trous-de-loup",
  "trousseaux",
  "trunkfishes",
  "trymata",
  "tubae",
  "turves",
  "tympana",
  "tyros",
  "ubermenschen",
  "uglies",
  "uigurs",
  "ulnae",
  "ultimata",
  "umbilici",
  "umbones",
  "umbrae",
  "unci",
  "uncidia",
  "uredines",
  "uredinia",
  "uredosori",
  "urethrae",
  "urinalyses",
  "uteri",
  "utriculi",
  "uvulae",
  "vacua",
  "vagi",
  "vaginae",
  "valleculae",
  "vaporetti",
  "varices",
  "vasa",
  "vascula",
  "vela",
  "velamina",
  "velaria",
  "venae",
  "venae_cavae",
  "ventriculi",
  "vermes",
  "verrucae",
  "vertebrae",
  "vertices",
  "vertigines",
  "vertigoes",
  "vesicae",
  "vetoes",
  "vexilla",
  "viatica",
  "viatores",
  "vibracula",
  "vibrissae",
  "vice-chairman",
  "villi",
  "vimina",
  "vincula",
  "viragoes",
  "vires",
  "virtuosi",
  "vitae",
  "vitelli",
  "vittae",
  "vivaria",
  "voces",
  "volcanoes",
  "volkslieder",
  "volte",
  "volvae",
  "vorticellae",
  "vortices",
  "vulvae",
  "wagons-lits",
  "wahhabis",
  "wanderjahre",
  "weakfishes",
  "werewolves",
  "wharves",
  "whippers-in",
  "whitefishes",
  "wives",
  "wolffishes",
  "wolves",
  "woodlice",
  "wreckfishes",
  "wunderkinder",
  "xiphisterna",
  "yeshivahs",
  "yeshivoth",
  "yogin",
  "yourselves",
  "zamindaris",
  "zecchini",
  "zeroes",
  "zoa",
  "zoaeae",
  "zoeae",
  "zoeas",
  "zoonoses",
  "zoosporangia",
]
